// import store from '@/store'
// import Vue from 'vue'
import qs from 'qs'
import axios from 'axios'
// import router from '../router/index'
import { ElMessage } from 'element-plus'
import { getToken, removeToken } from '@/utils/auth'
// import serverConfig from '@/serverConfig'


// create an axios instance

// setTimeout(()=>{
  // console.log( Vue.prototype.$ajaxUrl)
// },1000)
const service = axios.create({
  baseURL: '/api',// serverConfig.production, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60*1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent multipart/form-data
    config.headers['Authorization']  = 'Basic bGFtcF93ZWI6bGFtcF93ZWJfc2VjcmV0'
    config.headers['tenant']  = 'MDAwMA=='
    // config.headers['token']  = 'Bearer test'
    config.headers['Content-Type']  = 'application/json'
    if (config.method == 'post') {         // 后台只支持application/x-www-form-urlencoded 提交，所有需要转换成对应的格式
      // config.data[cusConfig.tokenName] = cusConfig.tokenPrefix + token;
      // config.data = qs.stringify(config.data)
    } else {  // 后台除了post外其他的请求方式都通过URL传参
      // config.params = Object.assign(config.data, config.params);
      // if (config.params) {
        // config.params[cusConfig.tokenName] = cusConfig.tokenPrefix + token;
      // }
    }
    // config.headers['Access-Control-Allow-Origin'] = '*'
    if (getToken('Token')) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = 'Bearer ' + getToken('Token')
      config.headers['X-Auth-Token'] = JSON.parse(window.localStorage.getItem('User')).openstacktoken
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code == 401) {
      ElMessage({
        message: res.msg || '未经身份验证的访问。请先登录。',
        type: 'error',
        duration: 5 * 1000
      })
    } else if(res.code == 400) {
      ElMessage({
        message: res.msg || '操作异常。请检查响应正文以获取详细信息。',
        type: 'error',
        duration: 5 * 1000
      })
      // this.$router.push({path:'/login'})
      // removeToken('mobileToken')
      // this.$router.push({path:'/login'})
    } else if(res.code == 403) {
      ElMessage({
        message: res.msg || '未经身份验证的访问。请先登录。',
        type: 'error',
        duration: 5 * 1000
      })
      if(getToken('Token')) {
        removeToken('Token')
      } else if (getToken('mobileToken')) {
        removeToken('mobileToken')
      }
      location.reload()
    } else if(res.code == 500) {
        ElMessage({
          message: res.msg || '内部服务器错误 - 意外错误。请检查堆栈跟踪的响应正文。',
          type: 'error',
          duration: 5 * 1000
        })
        // this.$router.push({path:'/login'})
    } else if(res.code == 202) {
        ElMessage({
          message: res.msg || '操作仍在执行。请检查任务队列。',
          type: 'error',
          duration: 5 * 1000
        })
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    } else if(res.code === 0) {
      return res
    } else if(res.code == -1) {
      ElMessage({
        message: res.msg || '联系管理员',
        type: 'error',
        duration: 5 * 1000
      })
      return false
    } else {
      if (res.code === undefined) {
        return res
      } else {
        ElMessage({
          message: res.msg || '联系管理员',
          type: 'error',
          duration: 5 * 1000
        })
        return false
      }
    }
  },
  error => {
    if(error.response.data.code == 40001) {
      removeToken('Token')
      location.reload()
    }
    if(error.response.data.code == 40009) {
      removeToken('Token')
      location.reload()
    }
    if(error.response.data.code == 40005) {
      removeToken('Token')
      location.reload()
    }
    if(error.response.data.errorMsg && error.response.data.errorMsg.indexOf('401') !=-1) {
      // removeToken('Token')
      // location.reload()
    }
    ElMessage({
      message: error.response.data.msg,
      type: 'error',
      duration: 5 * 1000
    })
    // return Promise.reject(error)
  }
)

export default service
