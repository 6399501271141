<template>
    <div class="user">
        <Head :hide-banner="hideBanner" :siteInfo="siteInfo" />
        <TypeTab />
        <div class="edition_center">
            <!-- <Breadcrumb /> -->
            <div class="center display_flex ">
                <div class="menu">
                    <MenuList />
                </div>
                <div class="menu_page flex bg_fff">
                    <router-view />
                </div>
            </div>
        </div>
        <Foot :siteInfo="siteInfo" />
        <Sidebar v-if="!hideBanner" />
    </div>
</template>
<script>
import mixin from "../../views/mixin.js";
import Head from "../../components/Home/head.vue";
import TypeTab from "../../components/Home/type_tab.vue";
import Breadcrumb from "../../components/Breadcrumb/index.vue";
import MenuList from "../../components/User/menuList.vue";
import Foot from "../../components/Home/foot.vue";
import Sidebar from "../../components/Home/sidebar.vue";
export default {
    mixins: [mixin],
    data() {
        return {
            hideBanner: true,
        }
    },
    components: {
      Head,
      TypeTab,
      Breadcrumb,
      MenuList,
      Foot,
      Sidebar,
    },
    created() {
        this.getSiteId()
    },
    methods: {
        getData() {}
    },
}
</script>
<style lang="scss" scoped>
.center {
    .menu {
        width: 180px;
        overflow: hidden;
    }
    .menu_page {
        margin-left: 20px;
    }
}
</style>
