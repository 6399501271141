/**
 * 
 * @returns home
 */
 const HomeIndex = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/home_index.vue')
 const SearchList = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/search_list.vue')
 const GoodDetails = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/good_details.vue')
 const FootDetails = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/footDetails.vue')
 const ShoppingCartList = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/shopping_cart_list.vue')
 const OrderList = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/orderList.vue')
 const Payment = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/payment.vue')
 const Guide = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/guide.vue')
 const Vip = () => import ( /* webpackChunkName: "model_view" */ '../../views/Home/vip.vue')

 export default [
    {
	    path: '/guide', // 入门指引
	    name: 'Guide',
	    component: Guide,
        meta: [{
                requireAuth: false,
                name : "入门指引",
                path: "/guide"
            }]
	},
    {
	    path: '/footDetails', // 入门指引
	    name: 'FootDetails',
	    component: FootDetails,
        meta: [{
                requireAuth: false,
                name : "文章详情",
                path: "/footDetails"
            }]
	},
    {
	    path: '/vip', // 会员权益
	    name: 'Vip',
	    component: Vip,
        meta: [{
                requireAuth: true,
                name : "会员权益",
                path: "/vip"
            }]
	},
    {
	    path: '/homeIndex', // 首页
	    name: 'HomeIndex',
	    component: HomeIndex,
        meta: [{
                requireAuth: false,
                name : "首页",
                path: "/homeIndex"
            }]
	},
     {
	    path: '/searchList', // 搜索列表
	    name: 'SearchList',
	    component: SearchList,
        meta: [{
            requireAuth: false,
            name : "首页",
            path: "/homeIndex"
        },{
                requireAuth: false,
                name : "分类",
                path: "/searchList"
            }]
	},
    {
	    path: '/goodDetails', // 商品详情
	    name: 'GoodDetails',
	    component: GoodDetails,
	    meta: [{
            requireAuth: false,
            name : "首页",
            path: "/homeIndex"
        },{
            requireAuth: false,
            name : "商品详情",
            path: "/goodDetails"
        }]
	},
    {
	    path: '/shoppingCartList', // 购物车
	    name: 'ShoppingCartList',
	    component: ShoppingCartList,
	    meta: [{
            requireAuth: true,
            name : "首页",
            path: "/homeIndex"
        },{
            requireAuth: true,
            name : "购物车",
            path: "/shoppingCartList"
        }]
	},
    {
	    path: '/orderList', // 购买订单确认
	    name: 'OrderList',
	    component: OrderList,
	    meta: [{
            requireAuth: true,
            name : "首页",
            path: "/homeIndex"
        },{
            requireAuth: true,
            name : "提交订单",
            path: "/orderList"
        }]
	},
    {
	    path: '/payment', // 付款页面
	    name: 'Payment',
	    component: Payment,
	    meta: [{
            requireAuth: true,
            name : "首页",
            path: "/homeIndex"
        },{
            requireAuth: true,
            name : "支付订单",
            path: "/payment"
        }]
	}
 ]