<template>
	<div class="home" :class="!hideBanner? 'padd_top_136':''">
		<Head v-if="$route.path != '/homeIndex'" :hide-banner="hideBanner" :siteInfo="siteInfo" />
		<TypeTab v-if="$route.path != '/homeIndex'" />
		<router-view />
		<Foot v-if="$route.path != '/homeIndex'" :siteInfo="siteInfo" :footList="footList" />
		 <Sidebar :category-goods="categoryGoods" ref="sidebar" v-if="!hideBanner&&$route.path != '/homeIndex'" />
	</div>
</template>

<script>
	import mixin from '../../views/mixin.js'
	import Head from "../../components/Home/head.vue"
	import TypeTab from "../../components/Home/type_tab.vue"
	import TypeList from "../../components/Home/type_list.vue"
	import GoodList from "../../components/Home/good_list.vue"
	import Foot from "../../components/Home/foot.vue"
	import Sidebar from "../../components/Home/sidebar.vue"
	export default {
		mixins: [mixin],
		components: {
			Head,
			TypeTab,
			TypeList,
			GoodList,
			Foot,
			Sidebar
		},
		data() {
			return {
				scroll: '',
				typeListData: [], // 分类
				bannerData: [],  // 轮播图
				hotkeys: [], // 搜索
				categoryGoods: [],
				banner: false
			};
		},
		created() {
			this.getSiteId()
			// console.log(document, 'document')
			// (function() { var link = document.querySelector("link[rel*='icon']") || document.createElement('link'); link.type = 'image/x-icon'; link.rel = 'shortcut icon'; link.href = 'http://www.stackoverflow.com/favicon.ico'; document.getElementsByTagName('head')[0].appendChild(link); })();
		},
		methods: {
			getData() {}
		}
	};
</script>
<style lang="scss" scoped>
	.padd_top_136 {
		padding-top: 136px;
	}
	/deep/ .el-carousel {
		height: 476px !important;
	}

	/deep/ .el-carousel__container {
		height: 476px !important;
		background-repeat: no-repeat;
		width: 100%;
		background-size:cover; 
		img {
			width: 100%;
			height: auto;
		}
	}

	.moreplatform {
		padding: 20px 0;
		text-align: center;
		background-color: #fff;

		a {
			display: inline-block;
			width: 114px;
			height: 36px;
			background: #FFFFFF;
			border: 1px solid #CB261C;
			border-radius: 18px;
			line-height: 36px;
			color: #CB261C;
		}

		a:hover,
		a:focus {
			color: #fff;
			background: #CB261C;
		}
	}

	.home {
		.type_box {
			li {
				position: relative;
			}

			.banner {
				height: 466px;
				padding: 10px;
				padding-bottom: 0px;
				// padding-right: 0px;
			}
		}

		.platformgoods {
			background: #fff;
			text-align: center;
			li {
				width: calc(20% - 8.5px);
			}

			.goods li .pic img {
				width: 188px;
				margin: 35px 0 25px 0;
			}

			.clamp a {
				overflow: hidden;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				color: #111;
				text-align: center;
				line-height: 24px;
				height: 48px;
				font-size: 14px;
			}

			.goods li .title a:hover,
			.sprice a:hover {
				color: #CB261C !important;
			}

			.goods li .title {
				padding: 10px 10px 30px 10px;
			}
		}

		.floorMain {
			.gtitle {
				width: 100%;
				text-align: center;
				margin-top: 20px;

				h2 {
					line-height: 88px;
					height: 88px;
					font-size: 28px;
					font-weight: bold;
					color: #333333;
				}
			}

			.gtitle h2:before {
				content: "";
				display: inline-block;
				margin-right: 27px;
				width: 24px;
				height: 24px;
				background-size: contain;
				background-image: url(../../assets/img/l_icon.png);
				background-repeat: no-repeat;
			}

			.gtitle h2:after {
				content: "";
				display: inline-block;
				margin-left: 27px;
				transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				width: 24px;
				height: 24px;
				background-size: contain;
				background-image: url(../../assets/img/338019c9a5c3e54b4a0a84ec83f87ce0.png);
				background-repeat: no-repeat;
			}

			.swiper-container {
				width: 100%;
				height: 100%;
				margin: 0 auto;
				position: relative;
				overflow: hidden;
				list-style: none;
				padding: 0;
				z-index: 1;
				display: flex;
				background: #F9FAFC;
			}

			.swiper-slide {
				border-left: 1px solid #eee;
				height: 104px;
				width: 160px;
				text-align: center;
				font-size: 18px;
				/* background: #fff; */
				display: flex;
				justify-content: center;
				align-items: center;
				border-top: 4px solid #F9FAFC;
			}

			.active {
				border-top-color: #CB261C !important;
				background-color: #fff;
			}
		}

		.dobblegoods {
			.video_recommended {
				margin-bottom: 35px;
				width: 100%;

				a {
					color: #333;
					text-decoration: none;
				}

				ul {
					background-color: #fff;
					padding: 20px;
				}

				li {
					width: calc(33% - 10px);
					margin-right: 20px;

					.video_box {
						position: relative;
						display: inline-block;

						img {
							max-width: 100%;
						}

						.video_time {
							position: absolute;
							right: 15px;
							bottom: 15px;
							display: inline-block;
							background-color: rgba(0, 0, 0, 0.8);
							color: #fff;
							font-size: 16px;
							padding: 0 10px;
							border-radius: 5px;
							height: 26px;
							line-height: 26px;
							z-index: 2;
						}
					}

					.video_introduction {
						font-size: 16px;
						color: #333333;
						font-weight: bold;
						line-height: 24px;
						margin-top: 10px;
						display: -webkit-box;
						text-overflow: ellipsis;
						overflow: hidden;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}

					.video_release_time {
						display: flex;
						margin: 5px 0;
						align-items: center;

						img {
							height: 12px;
						}

						em {
							color: #999;
							font-size: 14px;
							line-height: 20px;
							display: inline-block;
							margin-left: 3px;
						}
					}
				}

				li:nth-last-child(1) {
					margin-right: 0px;
				}
			}
		}

		.foot_recommend {
			background-color: #fff;
			padding: 50px 0;
			width: 100%;
			margin-top: 40px;
			.content {
				min-width: 1200px;
				max-width: 1600px;
				margin: 0 auto;
				position: relative;
			}

			.foot_recommend_h5 {
				font-size: 28px;
				font-weight: bold;
				line-height: 40px;
				color: #333333;
				text-align: center;
			}

			.foot_recommend_p {
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				color: #707070;
				text-align: center;
				margin-top: 15px;
			}

			.foot_recommend_ul {
				display: flex;
				margin-top: 50px;

				li {
					border-right: 1px solid #f1f1f1;
					text-align: center;
					width: 25%;
					padding: 0 50px;

					img {
						width: 38px;
						height: auto;
					}

					h5 {
						font-size: 20px;
						font-weight: bold;
						line-height: 28px;
						color: #333333;
						text-align: center;
						margin-top: 15px;
					}

					p {
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						color: #333333;
						text-align: center;
						margin: 15px 0;
					}
				}
			}
		}

		
	}
</style>
