<template>
    <div class="login_box bg_fff">
        <div class="swiper-container edition_center">
            <div class="head_box display_flex">
                <div class="logo_img">
					<router-link :to="'/homeIndex?siteId='+ $store.state.siteId"><img :src="siteInfo.logo"></router-link>
				</div>
                <h2 class="registersteppage">欢迎免费注册</h2>
            </div>
            <div class="center display_flex">
                <div class="login_form flex">
                    <div class="registration_newh5">免费注册新的巴巴里狮账号</div>
                    <div class="registration_newp">
                        如果您不是巴巴里狮平台的客户，推荐您注册新的巴巴里狮账户，巴巴里狮平台将提供给您同等优质的海外仓货源服务
                    </div>
                    <div class="form_box">
                        <el-form ref="form" :model="loginForm" label-width="0px">
                          <el-form-item>
                            <el-input v-model="loginForm.username" placeholder="手机号"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-input v-model="loginForm.email" placeholder="邮箱"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-input type="password" v-model="loginForm.password" placeholder="密码"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-input type="password" v-model="loginForm.repassword" placeholder="请输入确认密码"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-button style="width: 100%;" type="primary" @click="onSubmit">注册</el-button>
                            <div class="btn_box"><router-link :to="'/login?siteId='+siteId">已有账号，马上<span class="color_red">登录</span></router-link></div>
                          </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="login_text flex">
                    <h5>推荐使用巴巴里狮账号授权注册</h5>
                    <p>如果您是巴巴里狮平台用户，且未授权注册过巴巴里狮平台，</p>
                    <p>推荐您使用巴巴里狮账号授权注册,注册后，将为您自动同步以下信息：</p>
                    <ul>
                        <li>取消您在巴巴里狮的商品订阅通知</li>
                        <li>禁用您在巴巴里狮的平台账号(无法载单、同步库存)</li>
                        <li>获得您的客户信息、实名认证信息</li>
                        <li>获得您的购物车、收藏夹、下载商品信息</li>
                        <li>获得您的收货地址、支付账号备案信息</li>
                        <li>获得您的VAT税号信息</li>
                    </ul>
                </div>
            </div>
        </div>
        <Foot :siteInfo="siteInfo" />
    </div>
</template>

<script>
import mixin from "../../views/mixin.js";
import Foot from "../../components/Home/foot.vue";
import md5 from 'js-md5'
export default {
    mixins: [mixin],
    data() {
        return {
            loginForm: {
                username: '',
                password: '',
                repassword: '',
                email: ''
            },
        }
    },
    components: {
      Foot,
    },
    created() {
        this.getSiteId()
    },
    methods: {
        getData() {},
        onSubmit() {
            if(this.loginForm.username && this.loginForm.password && this.loginForm.repassword) {
                let loginForm = {
                    username: this.loginForm.username,
                    email: this.loginForm.email,
                    password: md5(this.loginForm.password),
                    repassword: md5(this.loginForm.repassword),
                    siteId: this.$route.query.siteId? this.$route.query.siteId:this.siteId
                }
                this.$requestJSON({
      		      url: 'shop/user/register',
      		      method: 'POST',
                    data: loginForm
      		    }).then(res => {
      		    //   this.loading = false
      		       if(res.code === 0) {
      		            this.$message.success(res.msg)
                        this.$router.push({path:'/login' , query: {siteId: this.$route.query.siteId? this.$route.query.siteId:this.siteId}})
      		       } else {
      		         this.$message.error (res.msg)
      		       }
      		    })
            } else {
                this.$message.error ('请输入')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login_box {
    .registersteppage {
        font-size: 24px;
        line-height: 33px;
        color: #333333;
        margin-left: 10px;
        margin-top: 12px;
        font-weight: normal;
    }
    .swiper-container {
        padding: 15px 0;
        .logo_img {
			img {
				width: 200px;
			}
		}
    }
    .center {
        padding: 90px 0;
        justify-content: center;
        .registration_newh5 {
            font-size: 28px;
            line-height: 40px;
            color: #333333;
            margin-bottom: 10px;
        }
        .registration_newp {
            font-size: 14px;
            line-height: 26px;
            color: #333333;
            margin-bottom: 30px;
        }
        .btn_box {
            text-align: center;
            margin-top: 20px;
            a {
                color: #999;
            }
        }
        .login_text {
            width: 550px;
            border-left: 1px solid #D5D5D5;
            padding-left: 110px;
            margin-left: 110px;
            h5 {
                font-size: 28px;
                line-height: 40px;
                color: #333333;
                margin-bottom: 10px;
            }
            p {
                font-size: 14px;
                line-height: 26px;
                color: #333333;
            }
            ul {
                list-style: disc;
                margin-left: 18px;
                margin-bottom: 30px;
            }
            li {
                font-size: 14px;
                line-height: 26px;
                color: #333333;
                list-style: disc;
            }
            li::marker {
                unicode-bidi: isolate;
                font-variant-numeric: tabular-nums;
                text-transform: none;
                text-indent: 0px !important;
                text-align: start !important;
                text-align-last: start !important;
            }
        }
    }
}
</style>
