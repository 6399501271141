import Vue from 'vue'
import { createRouter, createWebHashHistory } from "vue-router"
import Home from '../views/Home/index.vue'
import User from '../views/User/index.vue'
import Login from '../views/Login/index.vue'
import Register from '../views/Login/register.vue'
// import { getToken } from '@/utils/auth'

// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return routerPush.call(this, location).catch(error => error)
// }
// Vue.use(VueRouter)

let UserRoutes = []
let HomeRoutes = []
const routerContext = require.context('./', true, /index\.js$/)
routerContext.keys().forEach(route => {
    if (route.startsWith('./index')) {
        return
    }
    if(route.indexOf("./user") != -1) {
        const routerModule = routerContext(route)
        UserRoutes = [...(routerModule.default || routerModule)]
    } else if (route.indexOf("./home") != -1) {
        const routerModule = routerContext(route)
        HomeRoutes = [...(routerModule.default || routerModule)]
    }
})

let defaultRoutes = [
    // 主页
    {
        path: '/',
        redirect: "/homeIndex"
    },
    {
        path: '/home', // pc端首页
        name: 'Home',
        component: Home,
        children: [...HomeRoutes]
    },
    {
	    path: '/user', // 个人中心
	    name: 'User',
	    component: User,
	    children: [...UserRoutes],
	},
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {//注册
        path: '/register',
        name: 'Register',
        component: Register
    }
]

const router = createRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    history: createWebHashHistory(),
    routes: defaultRoutes
})

// 路由拦截
router.beforeEach((to, from, next) => {
    // console.log(to.meta, 'to')
    // console.log(from, 'from')
    let domain = document.domain
    let siteId = to.query.siteId?to.query.siteId:from.query.siteId
    // window.localStorage.setItem(domain + siteId +'Token', res.data.token)
    if(to.meta&&to.meta[0]&&to.meta[0].requireAuth) {
        if(!window.localStorage.getItem(domain + siteId+'Token')) {
            next('/login?siteId=' + siteId)
        } else {
            next()
        }
    } else {
        next()
    }
//   登录权限
//   if (!window.localStorage.getItem('Token')) {
//     // console.log(to.meta.requireAuth)
//     // console.log(error.response.status)
//     if (!to.meta.requireAuth) {
//       console.log(to.fullPath)
//       // next('/login')
//       next({path:'/login',query:{url: to.fullPath} })
//     }else {
//       next()
//     }
//   } else {
    // next()
//   }
//   路由发生变化修改页面title
//   document.title = ''
//   console.log(to.meta[0].name, document.title)
//   if (to.meta[0]) {
//     document.title = `项目进度管理系统 - ${to.meta[0].name}`
//   } else {
//     document.title = '项目进度管理系统'
//   }
})
export default router