<template>
	<div class="profile_right" id="loutinav" :class="$route.path == '/homeIndex'? 'bottom_110':'top_32'">
		<div class="profile_right_cnt">
			<div class="profile_right_cnt_first"></div>
			<div v-if="$route.path == '/homeIndex'">
				<!-- <p :class="index===0? 'active':''" v-scroll-to="{el: '#platform',offset: -155}"><a class="a-anchors">平台爆款</a></p> -->
				<p v-for="(imte, i) in categoryGoods" :key="i" :class="index===i? 'active':''" v-scroll-to="{el: '#'+ imte.icon,offset: -155}"><a class="a-anchors">{{imte.name}}</a></p>
				<!-- <p :class="index===categoryGoods.length? 'active':''" v-scroll-to="{el: '#video',offset: -155}"><a class="a-anchors">视频推荐</a></p> -->
				<!-- <p :class="index==1? 'active':''" v-scroll-to="{el: '#weed',offset: -155,}"><a class="a-anchors">本周新品</a></p>
				<p :class="index==2? 'active':''" v-scroll-to="{el: '#boutique',offset: -155,}"><a class="a-anchors">精品推荐</a></p>
				<p :class="index==3? 'active':''" v-scroll-to="{el: '#one',offset: -155,}"><a class="a-anchors">第一届赛</a></p>
				<p :class="index==4? 'active':''" v-scroll-to="{el: '#USA',offset: -155,}"><a class="a-anchors">美国热卖</a></p>
				<p :class="index==5? 'active':''" v-scroll-to="{el: '#britain',offset: -155,}"><a class="a-anchors">英国热卖</a></p>
				<p :class="index==6? 'active':''" v-scroll-to="{el: '#home',offset: -155,}"><a class="a-anchors">家居园艺</a></p>
				<p :class="index==7? 'active':''" v-scroll-to="{el: '#toys',offset: -155,}"><a class="a-anchors">玩具礼品</a></p>
				<p :class="index==8? 'active':''" v-scroll-to="{el: '#parts',offset: -155,}"><a class="a-anchors">汽摩配件</a></p>
				<p :class="index==9? 'active':''" v-scroll-to="{el: '#mp4',offset: -155,}"><a class="a-anchors">视频推荐</a></p> -->
			</div>
			<p class="" @click="openGuide"><a class="a-anchors">入门指引</a></p>
			<div class="new_service_box">
				<span class="service_icon"></span>客服
			</div>
			<div class="new_service_box_on" v-scroll-to="'#app'">
				<span class="sticky_topic"></span>顶部
			</div>
			<div class="profile_floor_div"></div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			index: 0
		}
	},
	props: {
		categoryGoods: Array
	},
	mounted() {},
	methods: {
		openGuide () {
			const url = this.$router.resolve({path: '/guide', query: {siteId: this.$route.query.siteId?this.$route.query.siteId:this.siteId}})
			window.open(url.href, '_blank')
		}
	}
}
</script>

<style lang="scss" scoped>
.bottom_110 {
	// bottom: 10px;
	top: 150px;
	right: 10px;
}
.top_32 {
	top: 32%;
	right: 4.5%;
}
	#loutinav {
	    width: 100px;
	    position: fixed;
	    z-index: 1099;
		.profile_right_cnt {
		    border: 1px solid #EEEEEE;
			.new_service_box:hover {
					background: #CB261C;
					color: #fff;
					.service_icon {
						background-position: -2px 0;
					}
				}
				.new_service_box_on {
				    color: #CB261C !important;
					 text-align: center;
				    background-color: #fff;
				    width: 72px;
				    padding: 10px 13px;
				    color: #666;
				    cursor: pointer;
				    border-bottom: 1px dotted #eee;
				    display: flex;
				    justify-content: center;
				    align-items: center;
					.sticky_topic {
					display: inline-block;
				    width: 16px;
				    height: 16px;
				    overflow: hidden;
				    background-repeat: no-repeat;
				    margin: 0 5px 3px 5px;
				    background-image: url('../../assets/img/2e7b600d948a440c7901a240f5eec026.png');
				    background-position: -3px -14px;
				}
				}
			.new_service_box {
				    text-align: center;
				    background-color: #fff;
				    width: 72px;
				    padding: 10px 13px;
				    color: #666;
				    cursor: pointer;
				    border-bottom: 1px dotted #eee;
				    display: flex;
				    justify-content: center;
				    align-items: center;
					.service_icon {
				    display: inline-block;
				    width: 16px;
				    height: 16px;
				    overflow: hidden;
				    background-repeat: no-repeat;
				    margin: 0 5px 3px 5px;
				    background-image: url('../../assets/img/2e7b600d948a440c7901a240f5eec026.png');
				    background-position: -21px 0;
				}
				}
				.active {
					a {
						background: #CB261C;
						color: #fff;
					}
				}
			p {
				width: 98px;
				border-bottom: 1px dotted #eee;
				font-size: 12px;
				text-align: center;
				position: relative;
				cursor: pointer;
				background: #fff;
				color: #666;
				line-height: 20px;
				a {
					display: block;
					padding: 10px 13px;
				}
				a:hover {
					background: #CB261C;
					color: #fff;
				}
				
			}
		}
	}
</style>
