import store from '@/store'
import router from '@/router'
// import Vue from 'vue'
import qs from 'qs'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getToken, removeToken } from '@/utils/auth'
// import serverConfig from '@/serverConfig'


// create an axios instance

// setTimeout(()=>{
  // console.log( Vue.prototype.$ajaxUrl)
// },1000)
const service = axios.create({
  baseURL: 'https://www.babalish.com/api',// serverConfig.production, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60*1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Content-Type']  = 'application/x-www-form-urlencoded'
    // config.headers['Access-Control-Allow-Origin'] = '*'
    if (config.method == 'post') {         // 后台只支持application/x-www-form-urlencoded 提交，所有需要转换成对应的格式
      // config.data[cusConfig.tokenName] = cusConfig.tokenPrefix + token;
      config.data = qs.stringify(config.data)
    } else {  // 后台除了post外其他的请求方式都通过URL传参
      // config.params = Object.assign(config.data, config.params);
      // if (config.params) {
        // config.params[cusConfig.tokenName] = cusConfig.tokenPrefix + token;
      // }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code == 401) {
      ElMessage({
        message: res.message || '未经身份验证的访问。请先登录。',
        type: 'error',
        duration: 5 * 1000
      })
    } else if(res.code == 400) {
      ElMessage({
        message: res.message || '操作异常。请检查响应正文以获取详细信息。',
        type: 'error',
        duration: 5 * 1000
      })
      removeToken()
    } else if(res.code == 403) {
      ElMessage({
        message: res.message || '未经授权的访问。请检查您的权限。',
        type: 'error',
        duration: 5 * 1000
      })
      removeToken('Token')
      this.$router.push({path:'/login'})
    } else if(res.code == 500) {
      ElMessage({
          message: res.message || '内部服务器错误 - 意外错误。请检查堆栈跟踪的响应正文。',
          type: 'error',
          duration: 5 * 1000
        })
        this.$router.push({path:'/login'})
    } else if(res.code == 202) {
      ElMessage({
          message: res.message || '操作仍在执行。请检查任务队列。',
          type: 'error',
          duration: 5 * 1000
        })
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    } else if(res.code == 0) {
      return res
    } else {
      if (res.code === 1) {
        let domain = document.domain
        window.localStorage.removeItem(domain + store.state.siteId+'Token')
        router.replace({path: '/login?siteId='+store.state.siteId})
        return res
      } else {
        return res
      }
    }
  },
  error => {
    console.log('err' + error+'********') // for debug
    if(error.response.data.code == 40001) {
      removeToken('Token')
      location.reload()
    }
    if(error.response.data.code == 40009) {
      removeToken('Token')
      location.reload()
    }
    if(error.response.data.errorMsg && error.response.data.errorMsg.indexOf('401') !=-1) {
      removeToken('Token')
      location.reload()
    }
    ElMessage({
      message: error.response.data.msg,
      type: 'error',
      duration: 5 * 1000
    })
    return error
  }
)

export default service
