import {
		Search,
		ArrowRight,
		Plus,
		ArrowDown,
		ArrowUp,
		ArrowLeftBold,
		ArrowRightBold,
		Bottom,
		DCaret,
		Coin,
		Warning,
		CaretTop,
		CaretBottom,
		Delete,
		Close
	} from "@element-plus/icons";
	import Pagination from "../components/Pagination/index.vue";
export default {
	components: {
		Search,
		ArrowRight,
		Plus,
		ArrowDown,
		ArrowUp,
		ArrowLeftBold,
		ArrowRightBold,
		Bottom,
		DCaret,
		Coin,
		Warning,
		CaretTop,
		CaretBottom,
		Delete,
		Close,
		Pagination
	},
    data() {
     return {
        pageSize: 15,
        currentPage: 1,
        total: 1,
		fullscreenLoading: false, // 加载
		hideBanner: true,
		scroll: '',
		siteId: '', // siteId
		isFav: 0, // 是否收藏
		siteInfo: {}, // 头部和底部数据
		typeListData: [], // 商品分类
		rechargeVisible: false, // 充值中心
		loading: false, // 全局加载
		domain: document.domain, // 获取的站点域名
		ifLogin: false, // 是否登录
		footList: [],
     }
    },
	destroyed() {
		window.removeEventListener("scroll", this.monitorScroll, false)
	},
	mounted() {
		window.addEventListener('scroll', this.monitorScroll, false)
		window.addEventListener("beforeunload", this.fn)
  	},
	  watch: {
		  $route(to,from){
			window.scrollTo(0,0);
		  }
	  },
    methods: {
		// 滚动回到顶部
		fn() {
			window.scrollTo(0,0);
		},
       // 监听滚动条
		monitorScroll() {
			this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
			if(this.scroll>90) {
				this.hideBanner = false
			} else {
				this.hideBanner = true
			}
			// console.log((this.$refs.gtitle.offsetTop - 160) < this.scroll)
			// window.localStorage.setItem('Token', res.data.token)
		},
		// 获取siteId/获取icon
		getSiteId() {
			if(this.$route.query.siteId) {
				this.siteId = this.$route.query.siteId
				this.$store.state.siteId = this.siteId
				this.getIcon(this.siteId, 'id')
				this.getData() // 拿到siteId再请求接口
			} else {
				let domain = document.domain
				// let domain = 'shop.babalish.com'
				// console.log("获取的站点域名："+domain)
				// let data = {
				// 	domain: 'shop.babalish.com',
				// 	token: window.localStorage.getItem('Token')? window.localStorage.getItem('Token'):''
				// }
				this.getIcon(domain, 'domain')
			}
		},
		// 接口
		getIcon(type, name) {
			this.$requestJSON({
				url: 'shop/domain/' + type,
				method: 'POST'
			  }).then(res => {
			  //   this.loading = false
				 if(res.code === 0) {
					 if(name=='domain') {
						this.siteId = res.data.siteId
						this.$store.state.siteId = this.siteId
						this.ifLogin = window.localStorage.getItem(this.domain + this.siteId+'Token')? true:false
				   		this.getData() // 拿到siteId再请求接口
					 } else if (name=='id') {
						console.log(res)
					 }
					 this.$store.state.footData = res.data.footer
					 this.footList = res.data.footer
					 this.$store.state.siteInfo = res.data.siteInfo
					 this.siteInfo = res.data.siteInfo
					 this.typeListData = res.data.categoryTree
					 this.$store.state.typeListData = res.data.categoryTree
					 document.title = this.siteInfo.title
					 this.$store.state.title = this.siteInfo.title
				 } else {
				   this.$message.error (res.msg)
				 }
			  })
		},
		// 修改icon
		// getIcon() {
		// 	var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
		// 	link.type = 'image/x-icon'; link.rel = 'shortcut icon';
		// 	link.href = this.siteId;
		// 	document.getElementsByTagName('head')[0].appendChild(link);
		// },
		// 商品分类
		getGoodType() {
			this.$requestJSON({
				url: 'shop/' + this.$store.state.siteId,
				method: 'POST',
				data: {
					token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')? window.localStorage.getItem(this.domain + this.siteId+'Token'):''
				}
			  }).then(res => {
			  //   this.loading = false
				 if(res.code === 0) {
				   this.typeListData = res.data.categoryTree
				 } else {
				   this.$message.error (res.msg)
				 }
			  })
		},
		// 商品详情
		openGoodDetail(id) {
			const url = this.$router.resolve({path: '/GoodDetails' , query: {id: id, siteId: this.$store.state.siteId}})
			window.open(url.href, '_blank')
		},
		// 首页
		openHome() {
			this.$router.push({path:'/homeIndex',query:{siteId: this.$store.state.siteId}})
		},
		// 收藏
		addCollectionGood(id, istGetData, cartId) {
			if(!id) return
			let url = ''
			if(this.isFav == 0) {
			  url = 'shop/favorite/save'
			} else {
				url = 'shop/favorite/delByGoodsId'
			}
			this.$requestJSON({
				url: url,
				method: 'POST',
				data: {
					goodsId: id,
					siteId: this.$store.state.siteId,
					cartId: cartId,
					token: window.localStorage.getItem(this.domain + this.$store.state.siteId+'Token')
				}
			  }).then(res => {
			  //   this.loading = false
				 if(res.code === 0) {
				   this.isFav = this.isFav == 0? 1 : 0
				   if(istGetData == true) {
					 this.getData()
				   }
				 } else {
				   this.$message.error (res.msg)
				 }
			  })
		},
		// 翻页
		turnPages(data){
			if(data.page) {
			  this.currentPage = data.page
			}
			if(data.size) {
			  this.currentPage = 1
			  this.pageSize = data.size
			}
			// this.total = 0
			this.getData()
		  },
		// 分转化为元 - 正则解决精度
		regFenToYuan(fen){
			var num = fen;
			num=fen*0.01;
			num+='';
			var reg = num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
			num=num.replace(reg,'$1');
			num = this.toDecimal2(num)
			return num
		},
		toDecimal2(x) {
			var f = parseFloat(x);
			 if (isNaN(f)) {
			   return false;
			 }
			 var f = Math.round(x * 100) / 100;
			 var s = f.toString();
			 var rs = s.indexOf('.');
			 if (rs < 0) {
			   rs = s.length;
			   s += '.';
			 }
			 while (s.length <= rs + 2) {
			   s += '0';
			 }
			 return s;
	   },
	   // 关闭充值
	   cloeNo() {
		   this.rechargeVisible = false
		   this.getData()
	   }
    },
   }
