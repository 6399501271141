<template>
	<!-- tab栏 -->
	<div class="top_menunav_box bg_fff" :class="$route.path != '/home'? 'bb_2_red':'box_shadow'">
		<div class="edition_center">
		<div class="content display_flex">
			<div class="top_menunav_left" @mouseover="lookGoodType" @mouseleave="lookGoodTypeFalse">
				<a class="all_Goods "><img src="../../assets/img/allmenu.png">全部分类</a>
				<div class="good_type po_absolute"><TypeList v-if="$route.path != '/home'&& lookType" :typeListData='typeListData' /></div>
			</div>
	
			<div class="promotion_all" style=" width:calc(100% - 220px);">
				<div class="display_flex" style="width:100%; overflow:hidden;flex-wrap: wrap;height: 36px;">
					<a :style="type=='all'? 'color: #CB261C;':''" @click="openType('all')">全部商品 </a>
					<a :style="type=='isNow'? 'color: #CB261C;':''" @click="openType('isNow')">新品</a>
					<a :style="type=='isHow'? 'color: #CB261C;':''" @click="openType('isHow')">热销</a>
					<a :style="type=='isBrand'? 'color: #CB261C;':''" @click="openType('isBrand')">品牌</a>
					<a :style="type=='isClear'? 'color: #CB261C;':''" @click="openType('isClear')">清仓</a>
					<a :style="type=='isPromotion'? 'color: #CB261C;':''" @click="openType('isPromotion')">促销</a>
				</div>
			</div>
		</div>
		</div>
	</div>
</template>

<script>
import mixin from '../../views/mixin.js'
import TypeList from "./type_list.vue"
	export default {
		mixins: [mixin],
		components: {
			TypeList
		},
		data() {
			return {
				type: '',
				lookType: false,
				typeListData:[]
			}
		},
		created() {
			if(this.$route.query.type) {
				this.type = this.$route.query.type
			}
			// this.getSiteId()
			// this.typeListData = this.$store.state.typeListData
		},
		mounted() {
			setTimeout(()=>{
				this.typeListData = this.$store.state.typeListData
			},100)
		},
		methods: {
			// 鼠标移入/鼠标移出
			lookGoodType() {
				this.lookType = true
			},
			lookGoodTypeFalse() {
				this.lookType = false
			},
			openType(type) {
				this.type = type
				const url = this.$router.resolve({path: '/searchList' , query: {type: type, siteId: this.$store.state.siteId}})
				window.open(url.href, '_blank')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.bb_2_red {
		border-bottom: 2px solid #CB261C;
	}
	.box_shadow {
		box-shadow: 0 2px 4px -1px rgba(0,0,0,0.1);
	}
	.top_menunav_box {
		position: relative;
		.top_menunav_left {
			display: inline-block;
			width: 210px;
			position: relative;
		}
		.good_type {
			z-index: 110;
		}
		.all_Goods {
			background: #CB261C;
			line-height: 36px;
			text-align: center;
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			width: 210px;
			padding: 0px;
			display: inline-block;
	
			img {
				display: inline-block;
				width: 20px;
				margin: -3px 5px 0 0;
				vertical-align: middle;
			}
		}
	}
	.promotion_all {
		display: inline-block;
	
		a {
			font-size: 16px;
			display: inline-block;
			padding: 0 20px;
			color: #111;
			line-height: 40px;
			font-weight: bold;
		}
	
		a:hover,
		a:focus {
			color: #CB261C;
		}
	}
</style>
