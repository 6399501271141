<template>
	<div class="head_box">
		<!-- 头部 -->
		<div class="head" :class="{'head_fixed': !hideBanner, 'po-r':$route.query.select}" v-if="siteInfo">
			<div v-if="hideBanner" class="head_img">
				<img src="../../assets/img/head_img.jpg">
			</div>
			<div class="head_text">
				<div class="edition_center newheadtopl_box">
					<div class="newheadtopl">欢迎来到{{$store.state.title}}平台！</div>
					<div class="newheadtopl">
						<div class="head_list">
							<div class="lis">
								<router-link v-if="!ifLogin" class="red_text" :to='"/login?siteId="+$store.state.siteId'>登录</router-link>
								<router-link v-if="ifLogin" class="red_text" :to="'/userAdmin?siteId='+$store.state.siteId">个人中心</router-link>
							</div>
							<div class="lis">
								<router-link v-if="!ifLogin" :to="'/register?siteId='+$store.state.siteId">注册</router-link>
								<a v-if="ifLogin" @click="signOut">退出</a>
							</div>
							<div class="lis">
								<router-link :to="'/guide?siteId='+$store.state.siteId">入门指引</router-link>
							</div>
							<div class="lis">
								<router-link :to="'/vip?siteId='+$store.state.siteId">会员权益</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 搜索框 -->
			<div class="bg_fff">
				<div class="search_box edition_center display_flex">
				<div class="logo_img">
					<router-link :to="'/homeIndex?siteId='+$store.state.siteId"><img :src="siteInfo? siteInfo.logo:siteInfoData.logo"></router-link>
				</div>
				<!--搜索框-->
				<div class="searchnewbox">
					<div class="searchnew layui-form">
						<div class="searchtext">
							<form id="custom_form" autocomplete="off" style="width:100%;"
								onsubmit="javascript:return false;">
								<el-select class="select-input" v-model="select" placeholder="请选择">
  								      <el-option label="自营" value="0"></el-option>
  								      <el-option label="淘宝" value="1"></el-option>
  								      <el-option label="天猫" value="2"></el-option>
  								      <el-option label="阿里巴巴" value="3"></el-option>
  								      <!-- <el-option label="速卖通" value="4"></el-option> -->
  								    </el-select>
								<input class="searinput gtkwd" type="text" v-model="title"
									placeholder="请输入商品名称,零部件号等关键词进行搜索…" />
							</form>
							<button type="button" class="btnSubMit" @click="searchList(0)">
								<Search style="width: 1em; height: 1em; margin-right: 8px;"></Search>搜索
							</button>
							<i class="el-icon-delete" />
							<!-- <div class="hot-search">
				                <h3 style="margin-left:10px;">最近搜过</h3>
				                <ul class="history_search">
				                </ul>
				            </div> -->
						</div>
					</div>
					<div class="search_hotwords">
						<a @click="searchList(imte.name)" class="hot_search_click ga-custom-send" v-for="(imte, index) in hotkeys" :key="index">{{imte.name}}</a>
					</div>
				</div>
				<!--右侧-->
				<div class="otherfu login_bar">
					<ul class="display_flex">
						<li class="newsc">
							<router-link class="newsbtn" :to="'/message?siteId='+$store.state.siteId">
								<img src="../../assets/img/user_iconnews.png" />
								<p>消息中心</p>
							</router-link>
						</li>
						<li style="margin-left:0px;">
							<router-link :to="'/myOrderList?siteId='+$store.state.siteId">
								<!-- <img src="../../assets/img/user_icondown.png" /> -->
								<img src="../../assets/img/member2.png" />
								<p>我的订单</p>
							</router-link>
						</li>
						<li>
							<router-link :to="'/shoppingCartList?siteId='+$store.state.siteId">
								<img src="../../assets/img/user_iconcart.png" />
								<p>购物车</p>
							</router-link>
						</li>
					</ul>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from "../../views/mixin.js";
	export default {
		mixins: [mixin],
		props: {
			hideBanner: Boolean,
			hotkeys: Array,
			siteInfo: Object
		},
		data() {
			return {
				title: '', // 搜索
				select: '', // 搜索下拉框值
				siteInfoData: {}
			}
		},
		created() {
			this.title = this.$route.query.title? this.$route.query.title:''
			this.select = this.$route.query.select? this.$route.query.select:''
			// this.getSiteId()
		},
		mounted() {
			this.ifLogin = window.localStorage.getItem(this.domain + this.$store.state.siteId +'Token')? true:false
			this.$nextTick(()=>{
				setTimeout(()=>{
					this.siteInfoData = this.$store.state.siteInfo
			},100)
			})
		},
		methods: {
			getData() {},
			signOut() {
				this.ifLogin = false
				window.localStorage.removeItem(this.domain + this.$store.state.siteId+'Token')
				location.reload() // 刷新
			},
			searchList(key) {
				if(key) {
					this.title = key
				}
				const url = this.$router.resolve({path: '/searchList', query: {title: this.title, select: this.select, siteId: this.$store.state.siteId}})
				window.open(url.href, '_blank')
			},
		}
	}
</script>

<style lang="scss" scoped>
/deep/ .el-select {
	.el-input__inner {
		outline-style: none !important;
    	border: none !important; 
    	border-radius: 0px !important;
		height: 38px;
		background: #f5f5f5;
	}
	
}
/deep/ .el-select__popper .el-popper {
  z-index: 20300 !important;
  background: #000;
}
input{
    outline-style: none !important;
    border: none !important; 
    border-radius: 0px !important;
}
	.head_fixed {
		position: fixed;
		top: 0;
		z-index: 1000;
		left: 0;
	}
	.head {
		width: 100%;
		z-index: 10;
		.head_img {
			img {
				width: 100%;
				height: 80px;
			}
		}
	
		.head_text {
			background: #F2F3F7;
			border-bottom: 1px solid #E6E8EC;
			.newheadtopl_box {
				display: flex;
	
				.newheadtopl {
					flex: 1;
				}
	
				.head_list {
					display: flex;
					float: right;
	
					.lis {
						line-height: 34px;
	
						a {
							border-right: 1px solid #CCCCCC;
							padding: 0px 15px;
							color: #666;
						}
					}
				}
			}
		}
	}
	
	.search_box {
		padding: 30px 0 7px 0;
		justify-content: space-between;
		align-items: flex-start;
		.logo_img {
			img {
				width: 250px;
			}
		}
		.searchnewbox {
			width: calc(100% - 530px);
	
			// background: #000000;
			.searchnew {
				height: 38px;
				width: 80%;
				border: 2px solid #CB261C;
				border-radius: 3px;
				margin: 0 auto;
	
				.searchtext {
					display: flex;
					width: 100%;
	
					form {
						width: calc(100% - 100px) !important;
						display: flex;
						.select-input {
							width: 130px !important;
						}
						.searinput {
							padding-left: 5px;
							height: 38px;
							line-height: 38px;
							width: 100% !important;
						}
					}
				}
	
				button {
					background-color: #CB261C;
					color: #fff;
					text-align: center;
					font-size: 16px;
					height: 38px;
					line-height: 37px;
					width: 130px;
					padding: 0px;
					border: none;
				}
			}
		}
	}
	
	.search_hotwords {
		width: 80%;
		margin: 0 auto;
		overflow: hidden;
		height: 18px;
		margin-top: 5px;
	
		a {
			color: #999;
			line-height: 18px;
			margin-right: 20px;
			font-size: 12px;
			display: inline-block;
		}
	}
	.otherfu>ul>li>a:hover>p {
		color: #CB261C;
	}
	
	.otherfu {
		li {
			margin: 0 10px;
			// height: 40px;
		}
	
		a {
			color: #999;
			line-height: 30px;
			display: inline-block;
			position: relative;
			padding-right: 10px;
			text-align: center;
	
			img {
				width: 24px;
			}
	
			p {
				line-height: 22px;
				word-break: break-all;
			}
		}
	}
</style>
