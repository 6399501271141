<template>
    <div class="login_box bg_fff">
        <div class="swiper-container edition_center">
            <div class="head_box">
                <div class="logo_img">
					<router-link :to="'/homeIndex?siteId='+ $store.state.siteId"><img :src="siteInfo.logo"></router-link>
				</div>
            </div>
            <div class="center display_flex">
                <div class="login_form flex">
                    <div class="registration_newh5">使用巴巴里狮账号登录</div>
                    <div class="registration_newp">
                        如果您是巴巴里狮平台的新注册用户，或者您已经使用巴巴里狮账号授权注册了巴巴里狮账户，请您直接登录
                    </div>
                    <div class="form_box">
                        <el-form ref="form" :model="loginForm" label-width="0px">
                          <el-form-item>
                            <el-input v-model="loginForm.username" placeholder="用户名"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-input type="password" v-model="loginForm.password" placeholder="密码"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-button style="width: 100%;" type="primary" @click="onSubmit">登录</el-button>
                            <div class="btn_box"><a>忘记密码？</a><router-link :to="'/register?siteId='+siteId">注册</router-link></div>
                          </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="login_text flex">
                    <h5>推荐使用巴巴里狮账号授权注册</h5>
                    <p>如果您是巴巴里狮平台用户，且未授权注册过巴巴里狮平台，</p>
                    <p>推荐您使用巴巴里狮账号授权注册,注册后，将为您自动同步以下信息：</p>
                    <ul>
                        <li>取消您在巴巴里狮的商品订阅通知</li>
                        <li>禁用您在巴巴里狮的平台账号(无法载单、同步库存)</li>
                        <li>获得您的客户信息、实名认证信息</li>
                        <li>获得您的购物车、收藏夹、下载商品信息</li>
                        <li>获得您的收货地址、支付账号备案信息</li>
                        <li>获得您的VAT税号信息</li>
                    </ul>
                </div>
            </div>
        </div>
        <Foot :siteInfo="siteInfo" />
    </div>
</template>

<script>
import mixin from "../../views/mixin.js";
import Foot from "../../components/Home/foot.vue";
import md5 from 'js-md5'
export default {
    mixins: [mixin],
    data() {
        return {
            loginForm: {
                username: '',
                password: ''
            },
        }
    },
    components: {
      Foot,
    },
    created() {
        this.getSiteId()
    },
    methods: {
        getData() {},
        onSubmit() {
            if(this.loginForm.username && this.loginForm.password) {
                let loginForm = {
                    username: this.loginForm.username,
                    password: md5(this.loginForm.password),
                    siteId: this.$route.query.siteId? this.$route.query.siteId:this.siteId
                }
                this.$requestJSON({
      		      url: 'shop/user/login',
      		      method: 'POST',
                    data: loginForm
      		    }).then(res => {
      		    //   this.loading = false
      		       if(res.code === 0) {
      		            this.$message.success(res.msg)
                        this.$router.push({path:'/', query: {siteId: this.$route.query.siteId? this.$route.query.siteId:this.siteId}})
                        // window.localStorage.setItem('Token', res.data.token)
                        let domain = document.domain
                        let siteId = this.$route.query.siteId? this.$route.query.siteId:this.siteId
                        window.localStorage.setItem(domain + siteId +'Token', res.data.token)
      		       } else {
      		         this.$message.error (res.msg)
      		       }
      		    })
            } else {
                this.$message.error ('请输入')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login_box {
    .swiper-container {
        padding: 15px 0;
        .logo_img {
			img {
				width: 200px;
			}
		}
    }
    .center {
        padding: 90px 0;
        justify-content: center;
        .registration_newh5 {
            font-size: 28px;
            line-height: 40px;
            color: #333333;
            margin-bottom: 10px;
        }
        .registration_newp {
            font-size: 14px;
            line-height: 26px;
            color: #333333;
            margin-bottom: 30px;
        }
        .btn_box {
            text-align: right;
            margin-top: 20px;
            a {
                color: #999;
            }
        }
        .login_text {
            width: 550px;
            border-left: 1px solid #D5D5D5;
            padding-left: 110px;
            margin-left: 110px;
            h5 {
                font-size: 28px;
                line-height: 40px;
                color: #333333;
                margin-bottom: 10px;
            }
            p {
                font-size: 14px;
                line-height: 26px;
                color: #333333;
            }
            ul {
                list-style: disc;
                margin-left: 18px;
                margin-bottom: 30px;
            }
            li {
                font-size: 14px;
                line-height: 26px;
                color: #333333;
                list-style: disc;
            }
            li::marker {
                unicode-bidi: isolate;
                font-variant-numeric: tabular-nums;
                text-transform: none;
                text-indent: 0px !important;
                text-align: start !important;
                text-align-last: start !important;
            }
        }
    }
}
</style>
