<template>
	<div class="good_list">
		<div class="floorMain edition_center">
			<div class="gtitle">
				<h2>{{ title }}</h2>
			</div>
			<div class="goods firstimg">
				<div class="ulWrap cnellipsis">
					<ul class="display_flex flex_wrap baokuang">
						<li>
							<a ga-cate="custom template for sale" class="ga-custom-send"
								href="/subject/ap54437.html"><img :src="goodImg"></a>
						</li>
						<li v-for="(imte, index) in data" :key="index" @click="openGoodDetail(imte.id)">
							<div class="proitemIndx">
								<div class="pic">
									<a
										target="_blank">
										<img :src="imte.logo"
											:alt="imte.title" />
									</a>
								</div>
								<div class="title clamp">
									<a :title="imte.title"
										target="_blank">
										<span class="new_goodsbrand">
										</span>
										{{imte.title}}
									</a>
									<span class="price">
									</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mixin from "../../views/mixin.js";
	export default {
		mixins: [mixin],
		props: {
			title: String,
			data: Array,
			goodImg: String,
		},
		mounted() {},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	.floorMain {
		.baokuang {
			li {
			    text-align: center;
			    display: flex;
			    width: calc(20% - 10px);
			    background: #fff;
			    margin-right: 10px;
			    margin-top: 10px;
				cursor:pointer;
				.title {
				    padding: 10px 10px 30px 10px;
					a {
					    overflow: hidden;
					    -webkit-line-clamp: 2;
					    text-overflow: ellipsis;
					    display: -webkit-box;
					    -webkit-box-orient: vertical;
					    color: #111;
					    text-align: center;
					    line-height: 24px;
					    height: 48px;
					}
				}
				a {
					color: #333;
					text-decoration: none;
					img {
						width: 100%;
					}
				}
				a:hover,
				a:focus {
					color: #CB261C;
				}
			}
		}
		.gtitle {
			width: 100%;
			text-align: center;
			margin-top: 20px;
	
			h2 {
				line-height: 88px;
				height: 88px;
				font-size: 28px;
				font-weight: bold;
				color: #333333;
			}
		}
	
		.gtitle h2:before {
			content: "";
			display: inline-block;
			margin-right: 27px;
			width: 24px;
			height: 24px;
			background-size: contain;
			background-image: url(../../assets/img/l_icon.png);
			background-repeat: no-repeat;
		}
	
		.gtitle h2:after {
			content: "";
			display: inline-block;
			margin-left: 27px;
			transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-webkit-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			width: 24px;
			height: 24px;
			background-size: contain;
			background-image: url(../../assets/img/338019c9a5c3e54b4a0a84ec83f87ce0.png);
			background-repeat: no-repeat;
		}
	
		.swiper-container {
			width: 100%;
			height: 100%;
			margin: 0 auto;
			position: relative;
			overflow: hidden;
			list-style: none;
			padding: 0;
			z-index: 1;
			display: flex;
			background: #F9FAFC;
		}
	
		.swiper-slide {
			border-left: 1px solid #eee;
			height: 104px;
			width: 150px;
			text-align: center;
			font-size: 18px;
			/* background: #fff; */
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: 4px solid #F9FAFC;
		}
	
		.active {
			border-top-color: #CB261C !important;
			background-color: #fff;
		}
	}
	
</style>
