<template>
    <div class="location display_flex">
		<div v-for="(imte, i) in mentList" :key="i">
			<p v-if="mentList.length-1 != i" class="display_flex" @click="openPage(imte.path)">
				<a>{{imte.name}}</a>
				<ArrowRight style="width: 12px; height: 1em; margin-right: 8px;margin-top: 8px;" />
			</p>
			<h1 v-else>
				<a>{{imte.name}}</a>
			</h1>
		</div>
	</div>
</template>
<script>
import mixin from "../../views/mixin.js";
export default ({
    mixins: [mixin],
    data() {
        return {
			mentList: []
		}
    },
	created () {
		this.mentList = this.$route.matched[this.$route.matched.length-1].meta
	},
	methods: {
		 openPage(path) {
			// const url = this.$router.resolve({path: path, query: {siteId: this.$store.state.siteId}})
			// window.open(url.href, '_blank')
			this.$router.push({path: path,query:{siteId: this.$store.state.siteId}})
		}
	 }
})
</script>

<style lang="scss" scoped>
	.location {
		line-height: 30px;
		font-size: 14px;
		// margin: 10px 0;
		padding: 10px 0;

		p {
			color: #999;

			a {
				color: #999;
				padding: 0 5px;
			}
		}

		h1 {
			a {
				color: #333;
				padding: 0 5px;
			}
		}
	}
</style>