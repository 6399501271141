<template>
	<div class="foot_box">
		<!-- 底部 -->
		<div class="foot" v-if="footData">
			<div class="mwidth">
				<div class="footpadding">
					<!--帮助文档-->
					<div class="botblock" v-for="(imte, i) in footData.length?footData:footList" :key="i">
						<dl>
							<dt>{{imte.classify}}</dt>
							<dd v-for="(clien, index) in imte.article" :key="index" @click="openArticle(clien.id)">
								<a>{{clien.title}}</a>
							</dd>
						</dl>
					</div>
					<div class="botblock">
						<dl class="erweima">
							<dt>{{$store.state.title}}官方微信</dt>
							<dd><img :src="siteInfo? siteInfo.qrcode:siteInfoData.qrcode" /></dd>
						</dl>
					</div>
		
		
				</div>
		
			</div>
		</div>
		<!-- 友情链接合作伙伴 -->
		<div class="link_partners" v-if="$route.path == '/homeIndex'">
		    <div class="content">
		
		            <!-- <div class="partner">
		                <ul>
		                            <li><img src="../../assets/img/foot01.png"></li>
		                            <li><img src="../../assets/img/foot02.png"></li>
		                            <li><img src="../../assets/img/foot09.png"></li>
		                            <li><img src="../../assets/img/foot04.png"></li>
		                            <li><img src="../../assets/img/foot05.png"></li>
		                            <li><img src="../../assets/img/foot06.png"></li>
		                            <li><img src="../../assets/img/foot07.png"></li>
		                            <li><img src="../../assets/img/foot08.png"></li>
		                </ul>
		                <div class="clear"></div>
		            </div> -->
		                    <div class="links_new">
		                <p>
		                    友情链接：
		                        <a v-for="(imte, index) in flinks.length?flinks:flinksList" :key="index" target="_blank" :href="imte.url">{{imte.title}}</a>
		                </p>
		                <div class="clear"></div>
		            </div>
		    </div>
		</div>
		<!-- 版权信息 -->
		<div class="banquan">
		    <div class="all-station">
		        <span class="icon">
		            {{siteInfo?siteInfo.copyright:siteInfoData.copyright}}
		        </span>
		    </div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		siteInfo: Object,
		footList: Array,
		flinksList: Array,
		// flinks: Array
	},
	data() {
		return {
			footData: [],
			flinks: [],
			siteInfoData: {}
		}
	},
	watch: {
		$route(to,from){
			if(to.path == '/homeIndex') {
				setTimeout(()=>{
					this.flinks = this.$store.state.flinks
				},100)
			}
		  }
	},
	mounted() {
		this.$nextTick(()=>{
			setTimeout(()=>{
			this.footData = this.$store.state.footData
			this.siteInfoData = this.$store.state.siteInfo
			if(this.$route.path == '/homeIndex') {
				this.flinks = this.$store.state.flinks
			}
		},100)
		})
	},
	methods: {
		openArticle(id) {
			const url = this.$router.resolve({path: '/footDetails' , query: {id: id, siteId: this.$route.query.siteId}})
			window.open(url.href, '_blank')
		}
	}
}
</script>

<style lang="scss" scoped>
	.foot {
		background-color: #222323;
		min-width: 1250px;
	
		.mwidth {
			min-width: 1200px;
			max-width: 1500px;
			margin: 0 auto;
			width: 80%;
		}
	
		.footpadding {
			display: flex;
			justify-content: space-between;
			padding: 40px 0 30px 0;
		}
	
		.botblock {
			flex: 1;
			margin-right: 40px;
			max-width: 190px;
	
			dl dt {
				font-size: 16px;
				color: #fff;
				font-weight: 700;
				line-height: 28px;
				padding-bottom: 10px;
				border-bottom: 1px solid #4B4C4C;
				margin-bottom: 10px;
			}
	
			dd {
				a {
					color: #bdbdbd;
					line-height: 30px;
					display: inline-block;
				}
			}
	
			p {
				font-size: 16px;
				color: #fff;
				font-weight: 700;
				line-height: 28px;
				padding-bottom: 10px;
				border-bottom: 1px solid #4B4C4C;
				margin-bottom: 10px;
			}
	
			img {
				width: 150px;
			}
	
			.more_social_media {
				span {
					display: inline-block;
					width: 34px;
					height: 34px;
					overflow: hidden;
					background-repeat: no-repeat;
				}
	
				ul {
					display: flex;
	
					li {
						margin-right: 7px;
						position: relative;
					}
				}
	
				.douyin_icon {
					background-image: url(../../assets/img/2e7b600d948a440c7901a240f5eec026.png);
					background-position: 0 -30px
				}
	
				.douyin_icon:hover {
					background-position: 0 -68px;
				}
	
				.weibo_icon {
					background-image: url(../../assets/img/2e7b600d948a440c7901a240f5eec026.png);
					background-position: -34px -30px;
				}
	
				.weibo_icon:hover {
					background-position: -34px -68px;
				}
	
				.tengxun_icon {
					background-image: url(../../assets/img/2e7b600d948a440c7901a240f5eec026.png);
					background-position: -68px -30px;
				}
	
				.tengxun_icon:hover {
					background-position: -68px -68px;
				}
	
				.aiqiyi_icon {
					background-image: url(../../assets/img/2e7b600d948a440c7901a240f5eec026.png);
					background-position: -102px -30px;
				}
	
				.aiqiyi_icon:hover {
					background-position: -102px -68px;
				}
	
				.bilibili_icon {
					background-image: url(../../assets/img/2e7b600d948a440c7901a240f5eec026.png);
					background-position: -136px -30px;
				}
	
				.bilibili_icon:hover {
					background-position: -136px -68px;
				}
	
				.media_QRcode {
					display: none;
					position: absolute;
					background-color: #fff;
					// box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
					width: 120px;
					height: 120px;
					padding: 5px;
					top: 40px;
					left: 0px;
				}
			}
		}
	}
	.link_partners {
		width: 100%;
		text-align: center;
		background-color: #1b1b1b;
		color: #ccc;
		padding: 20px 0 20px 0;
		min-width: 1250px;
		.content {
		    min-width: 1200px;
		    max-width: 1500px;
		    margin: 0 auto;
		    width: 80%;
		    position: relative;
			.partner {
				ul {
				    display: flex;
				    flex-wrap: wrap;
				    justify-content: center;
				    margin-bottom: 25px;
					li {
						font-size: 16px;
						color: #999;
						font-weight: 700;
						margin-left: 45px;
						img {
							max-height: 30px;
						}
					}
				}
			}
			.links_new {
				p {
				    line-height: 40px;
				    font-size: 12px;
					color: rgba(255, 255, 255,0.5);
					a {
						color: rgba(255, 255, 255,0.5);
						display: inline-block;
						padding: 0 10px;
						line-height: 16px;
						font-size: 12px;
					}
					a:not(:last-child) {
					    border-right: 1px solid rgba(255, 255, 255,0.2);
					}
				}
			}
		}
	}
	.banquan {
	    width: 100%;
	    background-color: #1b1b1b;
	    color: #ccc;
	    padding: 15px 0;
	    min-width: 1250px;
	    border-top: 1px solid rgba(255, 255, 255,0.1);
		display: inline-block;
		text-align: center;
		.all-station {
		    position: relative;
		    display: inline-block;
			padding-right: 16px;
			p {
				color: rgba(255, 255, 255,0.5);
				padding: 5px 0;
				line-height: 24px;
				cursor: pointer;
				font-size: 12px;
				display: inline;
				.icon {
				    width: 16px;
				    height: 16px;
				    background-image: url(../../assets/img/2e7b600d948a440c7901a240f5eec026.png);
				    padding: 0px;
				    background-position: -40px 0px;
				    margin-right: 5px;
				    float: left;
				    margin-top: 3px;
				}
				span {
					a {
						color: rgba(255, 255, 255,0.5);
					}
					a:hover, a:focus {
					    color: #CB261C;
					}
				}
			}
		}
	}
</style>
