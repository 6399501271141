<template>
    <div class="pagination_box">
     <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[15, 20, 50, 100]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
</template>
<script>
export default {
    props: {
        currentPage: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 15
        },
        total: {
            type: Number,
            default: 0
        }
    },
    name: 'Pagination',
    data() {
        return {
            page: 1,
            size: 15,
            totalNum: 0
        }
    },
    mounted() {
        this.page = this.currentPage
        this.size = this.pageSize
        // this.totalNum= this.total
    },
    methods: {
        handleSizeChange(val) {
        //   console.log(`每页 ${val} 条`)
        // this.page= 1
        // this.size = val
        this.$emit('getData', {page: '', size: val})
        },
        handleCurrentChange(val) {
        //   console.log(`当前页: ${val}`)
        // this.page = val
        this.$emit('getData', {page: val, size: ''})
        }
    }
}
</script>
<style lang="scss" scoped>
.pagination_box {
    padding: 20px;
    text-align: right;
  }
</style>
