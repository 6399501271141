import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import imServerStore from './store/imServerStore.js'
// import Vuex from 'vuex'
// 新增代码：引入全部组件及样式
// import './assets/font/iconfont.css' // 图标
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./assets/css/index.css"
import "./assets/scss/index.scss"
import 'dayjs/locale/zh-cn' //中文
import locale from 'element-plus/es/locale/lang/zh-cn'
import request from './utils/request'
import requestJSON from './utils/requestJSON'
import { ElMessage } from 'element-plus'

// import requestUiApi from './utils/requestUiApi'
// import VCharts from 'v-charts'
import * as echarts from 'echarts'
// import myCharts from './utils/myCharts'

// createApp(App).use(store).use(router).use(ElementPlus, { locale }).use(VCharts, VCharts).mount('#app')
const app = createApp(App)
const VueScrollTo = require('vue-scrollto')

// app.use(App)
app.use(store)
app.use(router)
app.use(VueScrollTo)
// app.use(VueScrollTo, {
//     container: '#app',   // 滚动元素
//     duration: 500,  // 动画时长
//     easing: 'ease',  // 动画曲线
//     offset: 520,  // 滚动终点距离父元素顶部距离
//     force: true,  // 强制立即执行，即便元素是可见的
//     cancelable: true,
//     onStart: false,
//     onDone: false,
//     onCancel: false,
//     x: false, // x 轴禁止滚动
//     y: true
//   })
// app.use(echarts)
// app.use(imServerStore)

// 新增代码：注册全部组件
app.use(ElementPlus, { locale: locale })
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$url = '/api'
app.config.globalProperties.$request = request
app.config.globalProperties.$requestJSON = requestJSON
app.config.globalProperties.$message = ElMessage
// app.use({
//     mounted () {
//         document.dispatchEvent( new Event( 'render-event' ))
//         }
// })
app.mount('#app')
