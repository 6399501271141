<template>
	<div class="leftnav">
		<ul class="navlist">
			<li @mouseover="choiceType(i)" @mouseleave="choiceTypeFalse(i)" v-for="(imte, i) in typeListData" :key="i">
				<a class="cln" target="_blank" @click="openGoodType(imte.id)">
					<span class="new_category_icon"
						:style="'background-image: url(' + imte.logo + ')'"></span>
					{{imte.name}}
				</a>
				<div class="subbox display_flex" v-if="isType !== false&&isType==i">
					<div class="subbox_items">
						<div class="item" v-for="(children, index) in imte.children" :key="index">
							<p>
								<a target="_blank" @click="openGoodType(children.id)">
									{{children.name}}
								</a>
								<ArrowRight
									style="width: 12px; height: 1em; margin-right: 8px;margin-top: 11px;" />
	
							</p>
							<div class="newnav_secondbox" >
								<a target="_blank" @click="openGoodType(child.id)" v-for="(child, child_i) in children.children" :key="child_i">
									{{child.name}}
								</a>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	import mixin from '../../views/mixin.js'
	export default {
		mixins: [mixin],
		data() {
			return {
				isType: false,
			}
		},
		props: {
			typeListData: Array
		},
		mounted() {
			// console.log(this.typeListData, '参数')
		},
		methods: {
			// 鼠标移入
			choiceType(i) {
				this.isType = i
			},
			// 鼠标移出
			choiceTypeFalse(i) {
				this.isType = false
			},
			// 点击商品分类
			openGoodType(id) {
				const url = this.$router.resolve({path: '/searchList' , query: {categoryId: id, siteId: this.$store.state.siteId}})
				window.open(url.href, '_blank')

			}
		}
	}
</script>

<style lang="scss" scoped>
	.navlist {
		margin: 0px;
		padding: 10px 0;
		width: 210px;
		border-top: 0 none;
		background: #fff;
		height: 466px;
		position: relative;
		a:hover,
		a:focus {
			color: #CB261C;
			.new_category_icon {
				    background-position: -20px 0px;
			}
		}
	
		a {
			line-height: 38px;
			height: 38px;
			padding-left: 25px;
			display: inherit;
			font-size: 14px;
			padding: 0 0 0 20px;
			z-index: 6;
			margin: 0 10px;
			list-style-position: inside;
			color: #333;
	
			.new_category_icon {
				width: 20px;
				height: 20px;
				float: left;
				margin: 9px 10px 9px 0;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: 40px;
			}
		}
	}
	.subbox {
		position: absolute;
		left: 209px;
		z-index: 100;
		justify-content: space-between;
		width: 1052px;
		background-color: #fff;
		top: 0px;
		padding: 10px;
		height: 466px !important;
	
		// display: flex;
		.subbox_items {
			width: calc(100% - 280px);
			margin-right: 20px;
	
			.item {
				display: flex;
				margin: 5px 0;
				height: 27px !important;
				overflow-y: hidden;
	
				P {
					display: flex;
					display: inline-flex !important;
					word-break: keep-all !important;
					height: 27px !important;
				}
	
				.newnav_secondbox {
					/* width: calc(100% - 170px); */
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
	
		.nav_bannerimg {
			width: 260px;
	
			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
</style>
