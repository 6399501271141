/**
 * 
 * @returns 账号管理
 */
 const UserAdmin = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/user.vue')
 const AddressList = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/addressList.vue')
 const AccountSecurity = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/accountSecurity.vue')
 const PayPassword = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/payPassword.vue')
 const LoginPassword = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/loginPassword.vue')
 const Message = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/message.vue')
 const MessageDetails = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/message_details.vue')
 const AfterSale = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/afterSale.vue')
 const AfterSaleDetail = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/afterSaleDetail.vue')
 const AfterSalesInformation = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/afterSalesInformation.vue')
 const MyOrderList = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/orderList.vue')
 const EvaluateList = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/evaluateList.vue')
 const GetEvaluate = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/getEvaluate.vue')
 const OrderDetail = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/orderDetail.vue')
 const CollectionList = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/collectionList.vue')
 const BillList = () => import ( /* webpackChunkName: "model_view" */ '../../views/User/user/billList.vue')
 export default [
     {
         path: '/userAdmin',
         name: 'UserAdmin',
         meta: [{
             requireAuth: true,
             name : "账号管理",
             path: "/userAdmin"
         }],
         component: UserAdmin
     },{
        path: '/addressList',
        name: 'AddressList',
        meta: [{
            requireAuth: true,
            name : "地址管理",
            path: "/addressList"
        }],
        component: AddressList
    },{
        path: '/accountSecurity',
        name: 'AccountSecurity',
        meta: [{
            requireAuth: true,
            name : "账户安全",
            path: "/accountSecurity"
        }],
        component: AccountSecurity
    },{
        path: '/payPassword',
        name: 'PayPassword',
        meta: [{
            requireAuth: true,
            name : "账户安全",
            path: "/accountSecurity"
        },{
            requireAuth: true,
            name : "支付密码",
            path: "/payPassword"
        }],
        component: PayPassword
    },{
        path: '/loginPassword',
        name: 'LoginPassword',
        meta: [{
            requireAuth: true,
            name : "账户安全",
            path: "/accountSecurity"
        },{
            requireAuth: true,
            name : "登录密码",
            path: "/loginPassword"
        }],
        component: LoginPassword
    },{
        path: '/message',
        name: 'Message',
        meta: [{
            requireAuth: true,
            name : "消息中心",
            path: "/message"
        }],
        component: Message
    },{
        path: '/message_details',
        name: 'MessageDetails',
        meta: [{
            requireAuth: true,
            name : "消息中心",
            path: "/message"
        },{
            requireAuth: true,
            name : "消息详情",
            path: "/messageDetails"
        }],
        component: MessageDetails
    },{
        path: '/afterSale',
        name: 'AfterSale',
        meta: [{
            requireAuth: true,
            name : "售后服务",
            path: "/afterSale"
        }],
        component: AfterSale
    },{
        path: '/afterSaleDetail',
        name: 'AfterSaleDetail',
        meta: [{
            requireAuth: true,
            name : "售后服务",
            path: "/afterSale"
        },{
            requireAuth: true,
            name : "售后申请",
            path: "/afterSaleDetail"
        }],
        component: AfterSaleDetail
    },{
        path: '/afterSalesInformation',
        name: 'AfterSalesInformation',
        meta: [{
            requireAuth: true,
            name : "售后服务",
            path: "/afterSale"
        },{
            requireAuth: true,
            name : "售后信息",
            path: "/afterSalesInformation"
        }],
        component: AfterSalesInformation
    },{
        path: '/myOrderList',
        name: 'MyOrderList',
        meta: [{
            requireAuth: true,
            name : "我的订单",
            path: "/myOrderList"
        }],
        component: MyOrderList
    },{
        path: '/orderDetail',
        name: 'OrderDetail',
        meta: [{
            requireAuth: true,
            name : "首页",
            path: "/homeIndex"
        },{
            requireAuth: true,
            name : "我的订单",
            path: "/myOrderList"
        },{
            requireAuth: true,
            name : "订单详情",
            path: "/orderDetail"
        }],
        component: OrderDetail
    },{
        path: '/evaluateList',
        name: 'EvaluateList',
        meta: [{
            requireAuth: true,
            name : "评价晒单",
            path: "/evaluateList"
        }],
        component: EvaluateList
    },{
        path: '/getEvaluate',
        name: 'GetEvaluate',
        meta: [{
            requireAuth: true,
            name : "评价晒单",
            path: "/evaluateList"
        },{
            requireAuth: true,
            name : "评价",
            path: "/getEvaluate"
        }],
        component: GetEvaluate
    },{
        path: '/collectionList',
        name: 'CollectionList',
        meta: [{
            requireAuth: true,
            name : "收藏",
            path: "/collectionList"
        }],
        component: CollectionList
    },{
        path: '/billList',
        name: 'BillList',
        meta: [{
            requireAuth: true,
            name : "我的账单",
            path: "/billList"
        }],
        component:BillList
    }
 ]