<template>
    <div class="menu_box">
        <el-menu
          :default-active="active"
          class="el-menu-vertical-demo"
          style="width: 180px"
          router
          active-text-color="#CB261C"
          @open="handleOpen"
          @close="handleClose"
        >
            <div v-for="(imte, index) in menuData" :key="index">
              <el-sub-menu :index="imte.path">
                <template #title>
                  <img :src="imte.img" alt="">
                  <span>{{imte.title}}</span>
                </template>
                <div v-for="(child, i) in imte.children" :key="i">
                    <el-menu-item :index="child.path">{{child.title}}</el-menu-item>
                </div>
              </el-sub-menu>
            </div>
        </el-menu>
    </div>
</template>
<script>
import mixin from "../../views/mixin.js";
export default {
    mixins: [mixin],
    data() {
        return {
            active: '',
            menuData: []
        }
    },
    created() {
        this.active = this.$route.fullPath
        console.log(this.$route.fullPath, 'this.$route.fullPath')
    },
    mounted() {
        this.getData()
    },
    watch: {
        $route() {
            this.active = this.$route.fullPath
        }
    },
    methods: {
        getData() {
           this.menuData = [{
                title: '个人中心',
                img: require('../../assets/img/member.png'),
                path: 'user',
                children: [{
                    title: '个人中心',
                    path: '/userAdmin?siteId='+this.$route.query.siteId
                },{
                    title: '地址管理',
                    path: '/addressList?siteId='+this.$route.query.siteId
                },{
                    title: '账户安全',
                    path: '/accountSecurity?siteId='+this.$route.query.siteId
                }]
            },{
                title: '客户服务',
                img: require('../../assets/img/member3.png'),
                path: 'service',
                children: [{
                    title: '消息中心',
                    path: '/message?siteId='+this.$route.query.siteId
                },{
                    title: '售后服务',
                    path: '/afterSale?siteId='+this.$route.query.siteId
                }]
            },{
                title: '商品管理',
                img: require('../../assets/img/member1.png'),
                path: 'goods',
                children: [{
                    title: '购物车',
                    path: '/shoppingCartList?siteId='+this.$route.query.siteId
                },{
                    title: '收藏夹',
                    path: '/collectionList?siteId='+this.$route.query.siteId
                }
                // ,{
                //     title: '品牌授权',
                //     path: '/user'
                // },{
                //     title: '商品通知',
                //     path: '/user'
                // }
                ]
            },{
                title: '订单管理',
                img: require('../../assets/img/member2.png'),
                path: 'order',
                children: [{
                    title: '我的订单',
                    path: '/myOrderList?siteId='+this.$route.query.siteId
                },{
                    title: '评价晒单',
                    path: '/evaluateList?siteId='+this.$route.query.siteId
                }
                //,{
                //     title: '批量下单',
                //     path: '/user'
                // },{
                //     title: '异常订单',
                //     path: '/user'
                // }
                ]
            }
            // ,{
            //     title: '客户服务',
            //     img: require('../../assets/img/member3.png'),
            //     path: 'customer',
            //     children: [{
            //         title: '客户服务',
            //         path: '/user'
            //     },{
            //         title: '消息中心',
            //         path: '/user'
            //     },{
            //         title: '下载中心',
            //         path: '/user'
            //     },{
            //         title: '意见反馈',
            //         path: '/user'
            //     }]
            // },{
            //     title: '第三方平台',
            //     img: require('../../assets/img/member4.png'),
            //     path: 'party',
            //     children: [{
            //         title: '平台授权',
            //         path: '/user'
            //     },{
            //         title: '载单设置',
            //         path: '/user'
            //     },{
            //         title: 'SKU映射',
            //         path: '/user'
            //     },{
            //         title: '物流映射',
            //         path: '/user'
            //     },{
            //         title: '库存更新',
            //         path: '/user'
            //     },{
            //         title: '更新记录',
            //         path: '/user'
            //     }]
            // },{
            //     title: '圈货管理',
            //     img: require('../../assets/img/member5.png'),
            //     path: 'circleGoods',
            //     children: [{
            //         title: '圈货申请',
            //         path: '/user'
            //     },{
            //         title: '库存查询',
            //         path: '/user'
            //     },{
            //         title: '滞销单查询',
            //         path: '/user'
            //     },{
            //         title: '释放单查询',
            //         path: '/user'
            //     },{
            //         title: '缺货单查询',
            //         path: '/user'
            //     }]
            // },{
            //     title: '账户管理',
            //     img: require('../../assets/img/member6.png'),
            //     path: 'account',
            //     children: [{
            //         title: '发票管理',
            //         path: '/user'
            //     }]
            // }
            ,{
                title: '资产管理',
                img: require('../../assets/img/member7.png'),
                path: '/billList',
                children: [{
                //     title: '我的余额',
                //     path: '/user'
                // },{
                    title: '我的账单',
                    path: '/billList?siteId='+this.$route.query.siteId
                // },{
                //     title: '支付账号管理',
                //     path: '/user'
                }]
            }
            ]
        },
      handleOpen(key, keyPath) {
        console.log(key, keyPath)
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath)
      },
    },
}
</script>
<style lang="scss" scoped>
img {
    width: 24px;
    margin-right: 8px;
}
</style>