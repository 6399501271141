<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
//引入vue方法
import { ElConfigProvider } from 'element-plus'
//中文包
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default({
    components: {
      ElConfigProvider,
    },
    setup() {
      return {
        locale: zhCn,
      }
    },
  })
</script>

<style lang="scss">
/deep/ .el-select__popper {
  z-index: 20300 !important;
  background: #000 !important;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  width: 100%;
	height: 100%;
  background-color: #F2F3F7;
}
</style>
