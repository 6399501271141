import {createStore} from "vuex"
export default createStore({
    state: {
        newTime: 0,
        searchType: '',
        searchStock: {}, // 库存量
        searchPrice: {}, // 价格
        brandId: '0', // 品牌id
        search: false,
        siteId: '', // siteId
        footData: [],
        flinks: [],
        typeListData: [], // 头部分类
        title: '',
        siteInfo: {}
    },
    getters: {},
    mutations: {
        setNum(state, value) {
            state.newTime = value
        },
    },
    actions: {},
    modules: {}
})
